import styled from "styled-components";
import { TiLocation, TiPhone, TiMail } from "react-icons/ti";

export const ContactFormSection = styled.section`

    color: ${props => props.theme.colors.white};
    text-align: left;
    box-sizing: border-box;
    margin: 0;
    border: 0;
    display: block;
    padding: 3.438rem 0;

`;


export const SectionHeading = styled.div`
    text-align: left;
    color: ${props => props.theme.colors.black};
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
`;


export const ContactFormTitle = styled.h1`
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    font-weight: 500;
    line-height: 1.2;
    font-size: calc(1.3rem + .6vw);
    text-align: left;
    color: ${props => props.theme.colors.black};
    box-sizing: border-box;
    margin: 0;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border: 0;
    vertical-align: baseline;
    font-family:${props => props.theme.fontFamily.font1} ;

`;

export const ContactFormText = styled.p`
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    font-weight: 400;
    line-height: 1.6;
    font-size: 100%;    
    text-align: left;
    font-family:${props => props.theme.fontFamily.font2} ;
    color: ${props => props.theme.colors.grey1};
    padding-top: 0.8rem;
    box-sizing: border-box;
    margin-bottom: 1rem;
`;


export const ContactFormLink = styled.a`
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    font-weight: 400;
    line-height: 1.6;
    list-style: none;
    font-size: 100%;    
    text-align: left;
    font-family:${props => props.theme.fontFamily.font2};
    color: ${props => props.theme.colors.grey1};
    padding-top: 0.8rem;
    box-sizing: border-box;
    margin-bottom: 1rem;
    text-decoration: none !important;
    background-color: transparent;
    transition: all .3s ease-in-out;
    opacity: 0.8;
    &:hover{
        color: ${props => props.theme.colors.color1};
    }
`;

export const FooterAddress = styled.div`

    text-align: left;
    color: ${props => props.theme.colors.black};
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    -webkit-font-smoothing: antialiased;
`;

export const FooterAddressTitle = styled.h2`
    text-align: left;
    font-family:${props => props.theme.fontFamily.font1} ;
    color: ${props => props.theme.colors.color1};
    box-sizing: border-box;
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding: 0;
    border: 0;
    vertical-align: baseline;
    -webkit-font-smoothing: antialiased;

`;  


export const LocationIcon = styled(TiLocation)`
    margin-right: 10px;
    color: ${props => props.theme.colors.color6};
`;

export const PhoneIcon = styled(TiPhone)`
    margin-right: 10px;
    color: ${props => props.theme.colors.color6};
`;

export const MailIcon = styled(TiMail)`
    margin-right: 10px;
    color: ${props => props.theme.colors.color6};
`;

export const ContactFormContainer= styled.form`
    text-align: left;
    color: ${props => props.theme.colors.grey1};
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
    border-color: transparent;
    margin-bottom: 4rem;
    font-family:${props => props.theme.fontFamily.font2} ;
    
    .form-group{
        margin-bottom:1rem;
    }
    
    .form-control{
        margin-top:1rem;
        font-family:${props => props.theme.fontFamily.font2} ;
        padding: .375rem .75rem;
        font-size: 0.8rem;
        padding: .975rem 0.85rem;
        height: calc(2.98em + .75rem + 2px);
    }
`;
