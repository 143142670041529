import styled from "styled-components";

export const RoundedStyledButton = styled.button`


    box-sizing: border-box;
    margin: 0;
    overflow: visible;
    text-transform: none;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    font-size: 1rem;
    line-height: 1.5;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    -webkit-font-smoothing: antialiased;
    padding: 10px 25px;
    font-family: 'Open Sans', sans-serif;
    color: #fff;
    box-shadow: 0 20px 30px 0 ${props => props.theme.colors.shadowColor};
    background:  ${props => props.theme.colors.color1};
    border: 2px solid ${props => props.theme.colors.color1};
    border-radius: 1.875rem;
    -webkit-appearance: button;
    cursor: pointer;
    font-family: ${props => props.theme.fontFamily.font2};

       &:hover{
       
        background: transparent;
        color: ${props => props.theme.colors.color1};
        -webkit-box-shadow: none;
        box-shadow: none;
    }
   

`;