import styled from "styled-components";

export const WorkWithUsSection = styled.section`
    text-align: left;
    color: ${props => props.theme.colors.grey1};
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    margin: 0;
    border: 0;
    display: block;
    padding: 55px 0;

`;

export const TitleText = styled.h1`
    text-align: center!important;
    box-sizing: border-box;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
    -webkit-font-smoothing: antialiased;
    margin: 0 0 1rem;
    font-family: ${props => props.theme.fontFamily.font1};
    font-weight: 600;
    line-height: 1.21;
    color: initial;
    font-size: 2.441rem;
    
`; 

export const SubTitleText = styled.p`
    color: ${props => props.theme.colors.grey1};
    text-align: center!important;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: 0;
    font-family: ${props => props.theme.fontFamily.font2};
    vertical-align: baseline;
    -webkit-font-smoothing: antialiased;
    margin-bottom: 1.25rem;
    font-size: 1.25rem;
    font-weight: 300;
`; 
