import React from 'react';
import {
    PossitionContainer,
    PossitionInfo,
    PossitionImage,
    PosImage,
    Title,
    Seniority,
    FooterLinkText,
    Body } from './PositionsBunble.style';
import { useTranslation } from 'react-i18next';

function PositionsBunble({ title, seniorities,text1,image}){  
    const { t } = useTranslation();
    const reference="mailto:"+t('apply-mail')+"?subject=REF:"+title+"&body="+t('apply-mail-body');
   return (<>
        <PossitionContainer>
            <PosImage>
                <PossitionImage src={image} alt={title}/>
            </PosImage>
            <PossitionInfo className="possition-info">
                <Title className="mb-0">{title}</Title>
                <Seniority>{seniorities}</Seniority>
                <Body>{text1}</Body>
                <FooterLinkText href={reference}>{t('Aplicar')}</FooterLinkText>
            </PossitionInfo>
        </PossitionContainer>
    </>);
}

export default PositionsBunble;
