import React from 'react';
import {
    IntegrationsSectionContainer,
    IntegrationsTitle,
    IntegrationsSubTitle,
    TechnologyImage,
    SingleItem
 } from './IntegrationsSection.styles';
import { useTranslation } from 'react-i18next';
import { Container,Row } from 'react-bootstrap';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import BlockchainLogo from '../../../assets/img/integrations/blockchain-logo.svg';
import GoogleAddwordsLogo from '../../../assets/img/integrations/google-adwords-logo.png';
import GoogleAnalyticsLogo from '../../../assets/img/integrations/google-analytics-logo.png';
import MercadoLibreLogo from '../../../assets/img/integrations/mercado-libre-logo.png';
import MercadoPagoLogo from '../../../assets/img/integrations/mercado-pago-logo.png';
import GoogleMapsLogo from '../../../assets/img/integrations/google-maps-logo.png';
import PayplaLogo from '../../../assets/img/integrations/paypal-logo.png';
import SlackLogo from '../../../assets/img/integrations/slack-logo.png';
import TwilioLogo from '../../../assets/img/integrations/twilio-logo.png';
import WhatsappLogo from '../../../assets/img/integrations/whatsapp-logo.svg';
import OpenAiLogo from '../../../assets/img/integrations/openai-logo.png';
import TelegramLogo from '../../../assets/img/integrations/telegram-logo.png';
import { motion } from 'framer-motion';


function IntegrationsSection(){

   const { t } = useTranslation();


   return (
      <IntegrationsSectionContainer id="Integrations" >
        <motion.div
                  initial={{opacity:0}}
                  whileInView={{
                        opacity:1
                        }}
                        transition={{
                        duration:1,
                        }}
                        viewport= {{once:true}} 
                  >

        <Container fluid>
            <Row className="justify-content-center">
                <div className="col-md-12">
                    <div className="section-heading text-center mb-5 ">
                        <IntegrationsTitle>{t('integrations-title')}</IntegrationsTitle>
                        <IntegrationsSubTitle>{t('integrations-subtitle')}</IntegrationsSubTitle>
                    </div>
                 </div>
                 <Row className="justify-content-center" style={{padding: 0}}>
                    <div className="col-md-12" style={{padding: 0}}>
                        <OwlCarousel loop autoplay autoplayTimeout="2000" margin={15} paginationspeed="5200" className="dot-indicator owl-loaded owl-drag">
                            
                            <SingleItem className="item"><TechnologyImage src={BlockchainLogo} alt="Block Chain"/></SingleItem>
                            
                            <SingleItem className="item"><TechnologyImage src={GoogleAddwordsLogo} alt="Google Addwords"/></SingleItem>
                    
                            <SingleItem className="item "><TechnologyImage src={GoogleAnalyticsLogo} alt="Google Analytics"/></SingleItem>

                            <SingleItem className="item"><TechnologyImage src={MercadoLibreLogo} alt="Mercado Libre" /></SingleItem>

                            <SingleItem className="item"><TechnologyImage src={MercadoPagoLogo} alt="Mercado Pago"/></SingleItem>

                            <SingleItem className="item"><TechnologyImage src={PayplaLogo} alt="Paypal"/></SingleItem>

                            <SingleItem className="item"><TechnologyImage src={SlackLogo} alt="Slack"/></SingleItem>

                            <SingleItem className="item"><TechnologyImage src={TwilioLogo} alt="Twitter"/></SingleItem>
                            
                            <SingleItem className="item"><TechnologyImage src={GoogleMapsLogo} alt="Google Maps"/></SingleItem>

                            <SingleItem className="item"><TechnologyImage src={WhatsappLogo} alt="Whatsapp"/></SingleItem>

                            <SingleItem className="item"><TechnologyImage src={OpenAiLogo} alt="OpenAI" /></SingleItem>

                            <SingleItem className="item"><TechnologyImage src={TelegramLogo} alt="TelegramLogo" /></SingleItem>

                        </OwlCarousel>
                    </div>
                 </Row>   
            </Row>    
        </Container>

        </motion.div>
          
         
      </IntegrationsSectionContainer>);
}

export default IntegrationsSection;
