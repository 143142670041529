import {React,useState} from 'react';
import { Row, Col } from 'react-bootstrap';
import { Container } from 'react-grid-system';
import emailjs from 'emailjs-com';
import { ContactFormSection,
    SectionHeading,
    ContactFormTitle,
    FooterAddress,
    FooterAddressTitle,
    LocationIcon,
    PhoneIcon,
    MailIcon,
    ContactFormText,
    ContactFormLink,
    ContactFormContainer} from './ContactForm.styles';
import Modal from 'react-modal';
import RoundedColouredButton from '../../components/rounder-coloured-button/RoundedColouredButton';    
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion'

//import{ init } from 'emailjs-com';
//init("user_z3hJOjqmV4HH6cFhixfIb");


function ContactForm(){

   const { t } = useTranslation();

   const [datos, setDatos] = useState({})

    const handleInputChange = (event) => {
        setDatos({
            ...datos,
            [event.target.name] : event.target.value
        })
    }


    const [modalTitle, setModalTitle] = 
    useState({title: '', body: '', modalIsOpen: false});


    function showOkModal() {
        setModalTitle({title: t('contact-modal-ok-title'), body: t('contact-modal-ok-body'), modalIsOpen: true});
    }

    function showErrorModal() {
        setModalTitle({title: t('contact-modal-error-title'), body: t('contact-modal-error-body'), modalIsOpen: true});
    }


    function closeModal() {
        setModalTitle({title: '', body: '', modalIsOpen: false});
    }


    const enviarDatos = (event) => {
        event.preventDefault()
        console.log(datos)
        emailjs.send("service_qzk361n","template_byeb9zd",datos,"hu1DF1Z1enbEEi34P")
        .then(() => {
            showOkModal();
         }, () => {
            showErrorModal(); 
         });
    }

   return (
      <ContactFormSection id="contact" >
        <motion.div
                  initial={{opacity:0}}
                  whileInView={{
                      opacity:1
                    }}
                    transition={{
                      duration:1,
                    }}
                    viewport= {{once:true}} 
                 >
<Container>
         <Modal
                isOpen={modalTitle.modalIsOpen}
                onAfterOpen={()=>{}}
                onRequestClose={closeModal}
                style={{
                    content: {
                    top: '50%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    marginRight: '-50%',
                    transform: 'translate(-50%, -50%)',
                  }}}
                  contentLabel="Example Modal">
                <Container>
                    <Col>
                        <div className="col-md-12 d-flex align-items-center">
                            <ContactFormTitle>{modalTitle.title}</ContactFormTitle>
                        </div>
                        <div className="col-md-12 d-flex align-items-center">
                            <ContactFormText>{modalTitle.body}</ContactFormText>
                        </div>
                        <div className="col-md-12 d-flex align-items-center">
                            <RoundedColouredButton onClick={closeModal}>Ok</RoundedColouredButton>
                        </div>
                    </Col>
                </Container>

            </Modal>
            <Row>

                <div className="col-md-5">
                    <SectionHeading>
                        <ContactFormTitle>{t('contact-title')}</ContactFormTitle>
                        <ContactFormText>{t('contact-body')}</ContactFormText>
                    </SectionHeading>
                    <FooterAddress>
                        <FooterAddressTitle><strong>{t('contact-address-title')}</strong></FooterAddressTitle>
                        <ul className="list-unstyled support-list">
                            <li className="mb-2 d-flex align-items-center"><span><LocationIcon/></span><span><ContactFormText className="mb-2 d-flex align-items-center">{t('contact-address')}</ContactFormText></span></li>
                            <li className="mb-2 d-flex align-items-center"><span><PhoneIcon/></span><span><ContactFormLink href={t('phone-number-link')} className="mb-2 d-flex align-items-center">{t('contact-adress-phone')}</ContactFormLink></span></li>
                            <li className="mb-2 d-flex align-items-center"><span><MailIcon/></span><span><ContactFormLink href={t('email-link')}className="mb-2 d-flex align-items-center">{t('contact-adress-email')}</ContactFormLink></span></li>
                        </ul>
                    </FooterAddress>
                </div>
                <div className="col-md-7">
                    <ContactFormContainer method="POST" id="contactForm1" onSubmit={enviarDatos}>
                        <ContactFormTitle>{t('contact-address-title2')}</ContactFormTitle>
                        <div className="row">
                            <div className="col-sm-6 col-12">
                                <div className="form-group"><input type="text" className="form-control" name="name" placeholder={t('contact-form-name-placeholder')} required onChange={handleInputChange} /></div>
                            </div>
                            <div className="col-sm-6 col-12">
                                <div className="form-group"><input type="email" className="form-control" name="email" placeholder={t('contact-form-email-placeholder')}required onChange={handleInputChange}/></div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6 col-12">
                                <div className="form-group"><input type="text" name="phone" className="form-control" id="phone" placeholder={t('contact-form-phone-placeholder')} onChange={handleInputChange}/></div>
                            </div>
                            <div className="col-sm-6 col-12">
                                <div className="form-group"><input type="text" name="company" size="40" className="form-control" id="company" placeholder={t('contact-form-company-placeholder')} onChange={handleInputChange}/></div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group"><textarea style={{minHeight:"10rem"}} name="message" id="message" className="form-control" rows="7" cols="25" placeholder={t('contact-form-message-placeholder')} onChange={handleInputChange}></textarea></div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 mt-3"><RoundedColouredButton type="submit" className="btn solid-btn" id="btnContactUs">{t('contact-form-button-text')}</RoundedColouredButton></div>
                        </div>
                    </ContactFormContainer>
                    <p className="form-message"></p>
                </div>
            </Row>

         </Container>

        </motion.div>
         
      </ContactFormSection>);
}

export default ContactForm;
