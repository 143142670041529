const theme = {
    fontFamily:{
      font1: "'Work Sans', sans-serif",
      font2: "'Source Sans Pro', sans-serif"
    },
    colors: {
       color1: "#00A0E0",
       color2: "#B3EAF4",
       color3: "#28D1D6",
       color4: "#DDEDF0",
       color5: "#6A6B6B",
       color6: "#082B3A",
       color7: "#0283E9",
       color8: "#007bff",
       white:  "#FFFFFF",
       black:  "#2E2E2E",
       grey1:  "#707070",
       shadowColor: "#00A0E030",
       grey2: "#f4f7fa"
    }
 }

 export default theme;
