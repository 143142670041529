import ReactGA from 'react-ga';


export const analyticsProvider = {
    initialize: ()=>{
      ReactGA.pageview(window.location.pathname + window.location.search);
    },
    sendAnaliticsEvent: (event)=> {
      console.log(`Analytics Event: $event`)
      ReactGA.send(event.toString());
    }
}