import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  es: {
    translation: {
      "whatsapp-me": "https://wa.me/+541156931852",
      "menu-itme-1": "Nosotros",
      "menu-itme-2": "Servicios",
      "menu-itme-3": "Portfolio",
      "menu-itme-4": "Unite a nosotros",
      "menu-itme-5": "Contacto",
      "header-title": "Convertimos tus metas en realidad",
      "header-subtitle": "Impulsa el éxito de tu empresa con nuestro amplio catálogo de servicios especializados y soluciones personalizadas",
      "header-chat-button": "Hablemos",
      "footer-mision": "Convertimos tus metas en realidad",
      "footer-other-title": "Links de Interés",
      "footer-support-title": "Soporte",
      "footer-location-title": "Ubicación",
      "footer-adress": "Remedios de Escalada de San Martín 960",
      "footer-state-description": "Buenos Aires, Argentina",
      "footer-telephone-number": "+54 1156931852",
      "footer-email": "info@td.com.ar",  
      "footer-page-link": "td.com.ar",  
      "footer-about-us-link-text": "Sobre Nosotros",
      "footer-contact-us-link-text": "Contacto",
      "footer-products-us-link-text": "Nuestros Productos",
      "footer-our-mission-link-text": "Misión y Visión",
      "footer-copyright-line1": "Copyrights © 2021",
      "footer-copyright-line2": "All rights reserved by,",
      "footer-copyright-line3": "Tech Dream S.A.",
      "services-title":"Servicios",
      "services-title-description":"Trabajemos juntos en la construcción de un proyecto personalizado que se ajuste a tus necesidades. Descubre nuestra amplia gama de servicios disponibles.",
      "services-card-development-mobile-title":"Mobile Software",
      "services-card-development-mobile-body":"Desarrollo de aplicaciones móviles personalizadas de alta calidad con mantenimiento y soporte continuo. Equipo de desarrolladores altamente capacitados.",
      "services-card-development-mobile-url":"https://www.linkedin.com/company/72696757/admin/products/torradodesarrollos-td-mobile-software-development-service/",
      "services-card-development-backend-title":"Custom Backend",
      "services-card-development-backend-body":"Expertos en desarrollo de sistemas backend a medida. Tecnologías de última generación para sistemas seguros, escalables y eficientes. Mantenimiento y soporte continuo.",
      "services-card-development-backend-url":"https://www.linkedin.com/company/72696757/admin/products/-td-custom-backend-development-service/",
      "services-card-digital-transformation-title":"Transformación Digital",
      "services-card-digital-transformation-body":"Mejoramos tus procesos y potenciamos tu negocio! \n Te acompañamos desde el primer día para que puedas desarrollarte de la mejor manera en esta nueva era digital.",
      "services-card-digital-transformation-url":"https://www.linkedin.com/company/72696757/admin/products/torradodesarrollos-td-digital-transformation-service/",
      "services-card-automatization-title":"Automatización",
      "services-card-automatization-body":" Servicio de automatización de procesos para el desarrollo de software que aumenta la eficiencia y calidad. Soluciones personalizadas y soporte continuo.",
      "services-card-automatization-url":"https://www.linkedin.com/company/72696757/admin/products/torradodesarrollos-td-automation/",
      "services-card-academy-title":"Mobile Academy",
      "services-card-academy-body":"Es una plataforma en línea de desarrollo móvil que ofrece cursos prácticos para estudiantes de todos los niveles. Aprenderás construyendo aplicaciones. También ofrecemos cursos y charlas para empresas.",
      "services-card-academy-url":"https://www.linkedin.com/company/72696757/admin/products/torradodesarrollos-td-mobile-academy/",
      "services-card-ai-integration-title":"Integracion IA",
      "services-card-ai-integration-body":"Ofrecemos servicios de integración de IA para automatizar procesos, mejorar decisiones y brindar una experiencia personalizada. Aprendizaje automático, procesamiento de lenguaje natural y chatbots.",
      "services-card-ai-integration-url":"https://www.linkedin.com/company/72696757/admin/products/td-techdream-td-ai-integration/",      
      "services-card-iot-title":"IOT",
      "services-card-iot-body":"Te guiamos a usar la informacion que genera tu empresa y te ayudamos a explotarla aumentando la eficiencia de tu negocio generando nuevas fuentes de ingresos.",
      "services-card-iot-url":"",
      "services-card-design-title":"Diseño UX/UI",
      "services-card-design-body":"Diseñamos tu app movil y web, brindando experiencias atractivas al usuario.",
      "services-card-design-url":"",
      "contact-title": "Hablemos",
      "contact-body": "Consultanos y empecemos a trabajar juntos!",
      "contact-address-title":"Nuestra Ubicación",
      "contact-address-title2":"Dejanos tu Consulta",
      "contact-address" : "Remedios de Escalada de San Martín 960",
      "contact-adress-phone": "+54 1156931852",
      "contact-adress-email": "info@td.com.ar",
      "contact-form-name-placeholder": "Nombre",
      "contact-form-email-placeholder": "E-mail",
      "contact-form-phone-placeholder": "Teléfono",
      "contact-form-company-placeholder": "Nombre de Empresa",
      "contact-form-message-placeholder": "¿ Cómo te podemos ayudar ?",
      "contact-form-button-text": "Envíar",
      "contact-modal-ok-title": "Muchas gracias por tu consulta!",
      "contact-modal-ok-body": "Te confirmamos que ya recibimos la misma, la estaremos contestando a la brevedad.",
      "contact-modal-error-title": "No pudimos procesar tu consulta.",
      "contact-modal-error-body": "Tuvimos un inconveniente al procesar tu consulta, por favor volvé a intentar si el error persiste, por favor contactanos por otro medio.",
      "phone-number-link": "tel:+54 1156931852",
      "email-link": "mailto:info@td.com.ar",
      "about-title":"Ayudamos a impulsar tu negocio",
      "about-title-light":" más rápido",
      "about-abstract":"En nuestra empresa, tenemos una estructura organizacional horizontal y funcional, con áreas interconectadas y retroalimentadas, con el objetivo de proporcionar soluciones integrales a nuestros clientes y mejorar continuamente la calidad de nuestros servicios y productos.",
      "about-item-title-development":"Desarrollo",
      "about-item-body-development":"En nuestro equipo trazamos el camino más óptimo para hacer realidad tu proyecto. Te acompañamos en cada paso del proceso.",
      "about-item-title-consultancy":"Consultoria",
      "about-item-body-consultancy":"Ayudamos a hacer realidad tu proyecto mediante la identificación del camino más óptimo para su ejecución y cumplimiento.",
      "about-item-title-design":"Diseño",
      "about-item-body-design":"Buscamos inspirarte y estimular tu negocio con una experiencia única al trabajar con nosotros. ¡Conoce nuestros servicios hoy!",
      "about-item-title-I-D":"I+D",
      "about-item-body-I-D":"Nos mantenemos a la vanguardia del mercado, utilizando nuevas tecnologías y desarrollos para ofrecer soluciones innovadoras y actualizadas.",
      "about-item-title-products":"Productos",
      "about-item-body-products":"Desarrollamos productos de alta calidad y cultivamos relaciones duraderas con nuestros clientes. ¡Contáctanos hoy para obtener más información!",
      "portfolio-title":"Casos de estudio",
      "portfolio-title-light":"Algunos logros en producción",
      "portfolio-description":"Nuestros servicios y productos estan a tu alcance para que puedas posicionarte mas arriba en el mercado",
      "technologies-title": "Tecnologías que Usamos",
      "technologies-subtitle": "Ponemos a tu disposición todas las técnologías de punta.",
      "integrations-title": "Hacemos Integraciones",
      "integrations-subtitle": "Integramos tu empresa al mundo con las siguientes plataformas",
      "work-with-us-title": "Te estamos buscando !",
      "work-with-us-subtitle": "Trabajá con nosotros, tenemos muchas búsquedas abiertas!",
      "possition-android-title": "Android Developer",
      "possition-android-seniority": "SR, SSR",
      "possition-android-texts": "Kotlin",
      "possition-ios-title": "iOS Developer",
      "possition-ios-seniority": "SR, SSR",
      "possition-ios-texts": "Swift",
      "possition-flutter-title": "Flutter \n Developer",
      "possition-flutter-seniority": "SR",
      "possition-flutter-texts": "Dart con conocimiento en desarrollo nativo",
      "possition-ui-ux-title": "UX / UI Designer",
      "possition-ui-ux-seniority": "SR, JR",
      "possition-ui-ux-texts": "Diseño Mobile y Web",
      "possition-qa-title": "QA Manual \n  Automation",
      "possition-qa-seniority": "SR, JR",
      "possition-qa-texts": "Junit, Dart, XCTest",
      "possition-react-title": "React Developer",
      "possition-react-seniority": "SR",
      "possition-react-texts": "React Js",
      "possition-nodejs-title": "NodeJS",
      "possition-nodejs-seniority": "SR",
      "possition-nodejs-texts": "Express, MongoDb",
      "apply-mail-body": "No te olvides de adjuntarnos tu CV y contarnos un poco de vos.",
      "apply-mail": "info@td.com.ar",
      "social-linkedin-title": "Linkedin",
      "social-instragram-title": "Instagram",
      "social-twitter-title": "Twitter",
      "social-linkedin-link": "https://www.linkedin.com/company/td-techdream",
      "social-instragram-link": "https://www.instagram.com/td_techdream/",
      "social-twitter-link": "https://twitter.com/TD_TechDream",
      "mision-link": "https://td.com.ar/vision-and-mission",
      "products-link": "https://td.com.ar/products"
    } 
  },
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: "es",
    interpolation: {
      escapeValue: true
    }
  });

  export default i18n;
