import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import {analyticsProvider} from '../../../providers/analytics/analytics';

import {
   Nav,
   NavLogo,
   NavLink,
   Bars,
   Close,
   NavMenu,
   ResponsiveNavMenu
} from "./NavBar.styles";
import { Container, Row } from "react-bootstrap";


const Navbar = () => {
    const { t } = useTranslation();
    const [ menuVisible, setMenuVisible ] = useState(true);
    let listener = null
    const [scrollState, setScrollState] = useState("top")
    
  
    useEffect(() => {
      listener = document.addEventListener("scroll", () => {
        var scrolled = document.scrollingElement.scrollTop
        if (scrolled >= 120) {
          if (scrollState !== "affix") {
            setScrollState("affix")
          }
        } else {
          if (scrollState !== "top") {
            setScrollState("top")
          }
        }
      })
      return () => {
        document.removeEventListener("scroll", listener)
      }
    }, [scrollState])


    const menu =[

        {
            "path": "/#aboutUs",
            "text": t('menu-itme-1'),
            "analytics": "nosotros_tap"
        },
        {
            "path": "/#services",
            "text": t('menu-itme-2'),
            "analytics": "servicios_tap"
        },
        /*{
            "path": "/#portfolio",
            "text": t('menu-itme-3'),
            "analytics": "portfolio_tap"
        },*/

        {
            "path": "/#join-us",
            "text": t('menu-itme-4'),
            "analytics": "busqueda_tap"
        },

        {
            "path": "/#contact",
            "text": t('menu-itme-5'),
            "analytics": "contact_tap"
        },
    
    ];

    const sendAnalitics = (value)=>{
        if(value){
            menu.forEach((menuItem)=>{
                if(menuItem.path == value){
                    analyticsProvider.sendAnaliticsEvent(menuItem.analytics);
                }
            });
        }
    }

    const scrollWidthOffset = (el) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -80; 
        window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 
    };

    const menuBar = menu.map((value,index)=> 
        <NavLink scroll={el => scrollWidthOffset(el)} key={index} valuesmooth="true"  to={value.path}  >
            {value.text}
        </NavLink>);

    const menuResponsive = menu.map((value,index)=> 
        <Row key={index} >
            <NavLink  valuesmooth="true"  to={value.path} activeClassName="selected" onClick={()=>{setMenuVisible(!menuVisible)}} >
                {value.text}
            </NavLink>
        </Row>);


    React.useEffect(() => {
        menuBar.forEach((value)=>{
            if(value.props.to == location.hash){
                sendAnalitics(value);
                value.onClick();
            }
        });
      }, [location])
  

    return (
        <>
           <Nav className={(scrollState == "affix" &&  menuVisible) ? "navbar-inverse affix" :"navbar-inverse" } >
            <NavLogo to="/#aboutUs"/>
               
           
            { menuVisible ? 
                <Bars onClick={()=>{setMenuVisible(!menuVisible);}}/> :
                <Close onClick={()=>{setMenuVisible(!menuVisible);}}/>
            }

            <NavMenu>

                {menuBar}

            </NavMenu> 

           </Nav> 

           <ResponsiveNavMenu style={{display: menuVisible? "none" : ""} }>
                <Container fluid >
                    {menuResponsive}
                </Container>

            </ResponsiveNavMenu> 
        </>
    );
};

export default Navbar;