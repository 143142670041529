import styled from "styled-components";
import { Row } from 'react-bootstrap';

export const ServicesSection = styled.section`
    text-align: left;
    box-sizing: border-box;
    border: 0;
    font: inherit;
    display: block;
    padding: 55px 0;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    -webkit-font-smoothing: antialiased;


    & .title{
        margin-bottom: 0rem;
        margin-top:0.5rem;
        border: 0;
        color: ${props => props.theme.colors.white};
        background-color: ${props => props.theme.colors.color8};
        padding: .25rem .5rem;
        font-size: 80%;
        font-weight: 700;
        font-family: ${props => props.theme.fontFamily.font1};
        text-align: center;
        padding-right: .875em;
        padding-left: .875em;
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
        border-radius: 50rem;
        margin-left: 10px;
        width: 90px;

    }

    & .title-description{
        color: ${props => props.theme.colors.black};
        font-size: 1.2rem;
        font-family: ${props => props.theme.fontFamily.font2};
        text-align: left;
        vertical-align: baseline;
        font-weight: 500;
        line-height: 1.2;
        padding:0;
        margin:0;
        border:0;
    }
`;





export const ServiceTitleContainer = styled(Row)`

    padding-top: 1rem;
    padding-bottom: 1rem;

`;




