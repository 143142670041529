import React from "react";
import {AboutSection,AboutContentLeft, DivImages, ImageCircle} from './About.styles';
import { Container, Row } from 'react-bootstrap';
import {FaLaptop,FaPen,FaChartBar,FaCogs,FaBoxOpen} from "react-icons/fa" ;
import { motion } from 'framer-motion'

import i1 from '../../../assets/img/about/i1.svg'
import i2 from '../../../assets/img/about/i2.svg'
import i3 from '../../../assets/img/about/i3.svg'



//import Lottie from "lottie-react";
//import Animation1 from '../../../assets/anim/section-about-animation4.json';
//import Animation2 from '../../../assets/anim/section-about-animation5.json';

import { useTranslation } from 'react-i18next';

export default function About(){

    const { t } = useTranslation();

    return(
        <AboutSection id="aboutUs">
            <motion.div
                  initial={{opacity:0}}
                  whileInView={{
                        opacity:1
                        }}
                        transition={{
                        duration:1,
                        }}
                        viewport= {{once:true}} 
            >
            <Container>
                <Row>  
                    <div className="col-md-6 d-flex justify-content-center">
                        <AboutContentLeft>
                            <h1 className="title">{t('about-title')}
                                <span className="title-light">{t('about-title-light')}</span>
                            </h1>
                            <p className="abstract">{t('about-abstract')}</p>
                            <div className="item">
                                <div className="item-title">
                                    <div className="card card-icon" >{React.createElement(FaLaptop)} </div>
                                        <h2 className="mb-0">{t('about-item-title-development')}</h2>
                                </div>
                                <p className="item-body">{t('about-item-body-development')}</p>
                            </div>
                            <div className="item">
                                <div className="item-title">
                                    <div className="card card-icon" >{React.createElement(FaChartBar)} </div>
                                    <h2 className="mb-0">{t('about-item-title-consultancy')}</h2>
                                </div><p className="item-body">{t('about-item-body-consultancy')}</p>
                            </div>
                            <div className="item">
                                <div className="item-title">
                                    <div className="card card-icon" >{React.createElement(FaPen)} </div>
                                    <h2 className="mb-0">{t('about-item-title-design')}</h2>
                                </div>
                                <p className="item-body">{t('about-item-body-design')}</p>
                            </div>
                            <div className="item">
                                <div className="item-title">
                                    <div className="card card-icon" >{React.createElement(FaCogs)} </div>
                                    <h2 className="mb-0">{t('about-item-title-I-D')}</h2>
                                </div>
                                <p className="item-body">{t('about-item-body-I-D')}</p>
                            </div>
                            <div className="item">
                                <div className="item-title">
                                    <div className="card card-icon" >{React.createElement(FaBoxOpen)} </div>
                                    <h2 className="mb-0">{t('about-item-title-products')}</h2>
                                </div>
                                <p className="item-body">{t('about-item-body-products')}</p>
                            </div>
                        </AboutContentLeft>
                    </div>
                    <div className="col-md-6 d-flex justify-content-center align-items-center">
                        <DivImages>
                            <ImageCircle src={i1} width='auto' height='auto'></ImageCircle>
                            <ImageCircle src={i2} width='auto' height='auto'></ImageCircle>
                            <ImageCircle src={i3} width='auto' height='auto'></ImageCircle>
                        </DivImages>
                        <div>
                        {/*<Lottie autoplay={true} loop={true} animationData={Animation1} style={{padding:"5rem"}}/>*/}
                        {/*<Lottie autoplay={true} loop={true} animationData={Animation2} style={{padding:"6rem"}}/>*/}
                        </div>
                    </div>
                </Row>
            </Container>
            </motion.div>
        </AboutSection>
    )

}