import React from 'react';
import {
    TechnologiesSectionContainer,
    TechnologiesTitle,
    TechnologiesSubTitle,
    TechnologyImage,
    SingleItem
 } from './TechnologiesSection.styles';
import { useTranslation } from 'react-i18next';
import { Container,Row } from 'react-bootstrap';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import AndroidLogo from '../../../assets/img/technologies/android-logo.png';
import AppleLogo from '../../../assets/img/technologies/apple-logo.png';
import NodeJsLogo from '../../../assets/img/technologies/node-js-logo.svg';
import AwsLogo from '../../../assets/img/technologies/aws-logo.svg';
import DockerLogo from '../../../assets/img/technologies/docker-logo.png';
import FirebaseLogo from '../../../assets/img/technologies/firebase-logo.png';
import AzureLogo from '../../../assets/img/technologies/azure-logo.svg';
import AtalssianLogo from '../../../assets/img/technologies/atlassian-logo.png';
import FigmaLogo from '../../../assets/img/technologies/figma-logo.png';
import FlutterLogo from '../../../assets/img/technologies/flutter-logo.png';
import GitlabLogo from '../../../assets/img/technologies/gitlab-logo.png';
import MongoDbLogo from '../../../assets/img/technologies/mongo-db-logo.png';
import KubernetesLogo from '../../../assets/img/technologies/kubernetes-logo.png';
import { motion } from 'framer-motion';


function TechnologiesSection(){

   const { t } = useTranslation();


   return (
      <TechnologiesSectionContainer id="technologies" >
         <motion.div
                  initial={{opacity:0}}
                  whileInView={{
                        opacity:1
                        }}
                        transition={{
                        duration:1,
                        }}
                        viewport= {{once:true}} 
                  >

        <Container fluid>
            <Row className="justify-content-center">
                <div className="col-md-12">
                    <div className="section-heading text-center mb-5 ">
                        <TechnologiesTitle>{t('technologies-title')}</TechnologiesTitle>
                        <TechnologiesSubTitle>{t('technologies-subtitle')}</TechnologiesSubTitle>
                    </div>
                 </div>
                 <Row className="justify-content-center" style={{padding: 0}}>
                    <div className="col-md-12" style={{padding: 0}}>
                        <OwlCarousel loop autoplay autoplayTimeout="3000" margin={20} paginationspeed="8000" className="dot-indicator owl-loaded owl-drag">
                            
                            <SingleItem className="item"><TechnologyImage src={AndroidLogo} alt="Android"/></SingleItem>
                            
                            <SingleItem className="item"><TechnologyImage src={AppleLogo} alt="Apple"/></SingleItem>
                    
                            <SingleItem className="item "><TechnologyImage src={NodeJsLogo} alt="NodeJS"/></SingleItem>

                            <SingleItem className="item"><TechnologyImage src={AwsLogo} alt="Amazon Web Services"/></SingleItem>

                            <SingleItem className="item"><TechnologyImage src={DockerLogo} alt="Docker"/></SingleItem>

                            <SingleItem className="item"><TechnologyImage src={AzureLogo} alt="Microsoft Azure"/></SingleItem>

                            <SingleItem className="item"><TechnologyImage src={FirebaseLogo} alt="Google Firebase"/></SingleItem>

                            <SingleItem className="item"><TechnologyImage src={AtalssianLogo} alt="Atlassian" /></SingleItem>

                            <SingleItem className="item"><TechnologyImage src={FigmaLogo} alt="Figma"/></SingleItem>

                            <SingleItem className="item"><TechnologyImage src={FlutterLogo} alt="Flutter"/></SingleItem>

                            <SingleItem className="item"><TechnologyImage src={GitlabLogo}  alt="Gitlab"/></SingleItem>

                            <SingleItem className="item"><TechnologyImage src={MongoDbLogo} alt="MongoDB"/></SingleItem>

                            <SingleItem className="item"><TechnologyImage src={KubernetesLogo} alt="KubernetesLogo"/></SingleItem>

                        </OwlCarousel>
                    </div>
                 </Row>   
            </Row>    
          </Container>
        </motion.div>

         
      </TechnologiesSectionContainer>);
}

export default TechnologiesSection;
