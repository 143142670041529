import React from 'react';
import { RoundedStyledButton } from "./RoundedButton.styles";

function RoundedButton({ children, onClick, disabled, type, style, href}){
   return (<>
         <RoundedStyledButton 
            href={href}
            style={style} 
            onClick={onClick}
            disabled={disabled}
            type={type}
            >
            
            {children}
         
         </RoundedStyledButton>
         </>
     );
}

export default RoundedButton;