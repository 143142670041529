import styled from "styled-components";

export const AboutSection = styled.section`
    
    text-align: left;
    color:${props => props.theme.colors.color5};
    box-sizing: border-box;
    margin: 0;
    border: 0;
    font: inherit;
    display: block;
    background: ${props => props.theme.colors.grey2};
    padding: 55px 0;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    -webkit-font-smoothing: antialiased;

`;



export const AboutContentLeft = styled.div`

    padding: 0;
    vertical-align: baseline;

    & .title{
        font-size: 2.541em;
        color: ${props => props.theme.colors.black};
        font-family: ${props => props.theme.fontFamily.font1};
        font-weight: 600;
        line-height: 1.21;
    }

    & .title-light{
        color: ${props => props.theme.colors.black};
        font-family: ${props => props.theme.fontFamily.font1};
        font-weight: 400;
        font-size: 2rem;
    }

    & .abstract{
        margin-bottom: 1.25em;
        font-family: ${props => props.theme.fontFamily.font2};
        color:${props => props.theme.colors.color5};
    }

    & .item{
        margin-bottom: 1.5rem!important;
    }

    & .item-title{
        font-family: ${props => props.theme.fontFamily.font1};
        color:${props => props.theme.colors.black};
        line-height: 1.2;
        font-weight: 900;
        display: flex!important;
        align-items: center!important;
        margin-bottom: .5rem!important;
    }

    & .card-icon{
        background: ${props => props.theme.colors.color4};
        padding: 15px;
        margin-right: 20px;
        border: 1px solid ${props => props.theme.colors.color4};
        color: ${props => props.theme.colors.color1};
    }

    & .item-body{
        text-align: left;
        color:${props => props.theme.colors.color5};
        box-sizing: border-box;
        font: inherit;
        font-family: ${props => props.theme.fontFamily.font2};
        vertical-align: baseline;
        margin-bottom: 1.25em;
    }

`;


export const DivImages = styled.div`
    display: flex;
    flex-direction: row;

    @media (min-width: 767.98px) {
        flex-direction: column;
    }
`;


export const ImageCircle = styled.img`
    animation: none;
    width: 100px;
    height: 100px;
    object-fit: cover; 
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    margin: 1rem;
    opacity: 90%;
    @media (min-width: 767.98px) {
        width: 220px;
        height: 220px; 
        margin-top:2rem;
        margin-right:0;
        margin-left:5rem;
        animation: frame 10s linear infinite;
    }
    @keyframes frame {
        0% {
            transform: scale(1);
        }
        50% {
            transform: scale(0.9);
        }
        90% {
            transform: scale(1);
        }
    }

`;



