import React from 'react';
import { StyledServicesCards, CardWrapper } from './ServicesCards.styles';

export default function ServicesCards({ title, body, icon, url}){

 return(
    <CardWrapper>
        <StyledServicesCards className="new-card card service-card">
            <div className="card-body" onClick={()=>{window.open(url)}}>
                <div className="pb-2">
                {React.createElement(icon)}            
                </div>
                <div className="pt-2 pb-3">
                    <h1 className="card-title">{title}</h1>
                    <p className="card-body-text">{body}</p>
                </div>
            </div>
        </StyledServicesCards>
    </CardWrapper>  
 )
}