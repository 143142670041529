import React,{useState,useEffect} from 'react';
import { NotionRenderer } from "react-notion";
import "react-notion/src/styles.css";
import "prismjs/themes/prism-tomorrow.css"; // only needed for code highlighting
// core styles shared by all of react-notion-x (required)


function Mission(){  

    const [data,setData] = useState({});

    useEffect(async () => {
        const dataGet = await fetch(
            "https://notion-api.splitbee.io/v1/page/8ed99eea99dd4ce8934c8f79b671c270"
          ).then(res => res.json());
          setData(dataGet);
    },[]);

   return (
    <div >
        <NotionRenderer blockMap={data} fullPage={true} darkMode={false} />
    </div>
   );
}

export default Mission;
