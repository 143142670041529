import React from 'react';
import { WorkWithUsSection,
    TitleText,
    SubTitleText} from './WorkWithUsSection.style';
import { useTranslation } from 'react-i18next';
import { Container,Row } from 'react-bootstrap';
import AndroidImage from '../../../assets/img/possition/android.png';
import QaImage from '../../../assets/img/possition/qa.png';
import IOSImage from '../../../assets/img/possition/apple.png';
import ReactImage from '../../../assets/img/possition/react.png';
import FlutterImage from '../../../assets/img/possition/flutter.png';
import UIUXImage from '../../../assets/img/possition/ui-ux.png';
import NodeJsImage from '../../../assets/img/possition/nodejs.png';
import PositionsBunble from '../../components/possitions-bumble/PositionsBunble';
import { motion } from 'framer-motion';


function WorkWithUs(){

   const { t } = useTranslation();


   return (
      <WorkWithUsSection id="join-us">

            <motion.div
                  initial={{opacity:0}}
                  whileInView={{
                      opacity:1
                    }}
                    transition={{
                      duration:1,
                    }}
                    viewport= {{once:true}} 
                 >

          <Container>
               <Row className="justify-content-center">
               <div className="col-lg-7 col-md-8">
                   <div className="section-heading text-center mb-5">
                       <TitleText>{t('work-with-us-title')}</TitleText>
                       <SubTitleText className="lead">{t('work-with-us-subtitle')}</SubTitleText>
                    </div>
                </div>
               </Row> 


               <Row>
                    <div className="col-lg-3 col-sm-6">
                        <PositionsBunble title={t("possition-android-title")} seniorities={t("possition-android-seniority")} text1={t("possition-android-texts")} image={AndroidImage}/>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                        <PositionsBunble title={t("possition-nodejs-title")} seniorities={t("possition-nodejs-seniority")} text1={t("possition-nodejs-texts")} image={NodeJsImage}/>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                        <PositionsBunble title={t("possition-flutter-title")} seniorities={t("possition-flutter-seniority")} text1={t("possition-flutter-texts")} image={FlutterImage}/>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                        <PositionsBunble title={t("possition-ios-title")} seniorities={t("possition-ios-seniority")} text1={t("possition-ios-texts")} image={IOSImage}/>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                        <PositionsBunble title={t("possition-ui-ux-title")} seniorities={t("possition-ui-ux-seniority")} text1={t("possition-ui-ux-texts")} image={UIUXImage}/>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                        <PositionsBunble title={t("possition-qa-title")} seniorities={t("possition-qa-seniority")} text1={t("possition-qa-texts")} image={QaImage}/>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                        <PositionsBunble title={t("possition-react-title")} seniorities={t("possition-react-seniority")} text1={t("possition-react-texts")} image={ReactImage}/>
                    </div>
                
                </Row>
          </Container>
          </motion.div>
      </WorkWithUsSection>);
}

export default WorkWithUs;
