import styled from "styled-components";

export const VideoHeader = styled.header`
   
    position: relative;
    background-color: black;

    width: 100%;
    height: 100%;
    overflow: hidden;


    @media (min-width: 350px) { 
        height: 22rem;
        min-height: 22rem;
    }

    @media (min-width: 575.98px) { 
        height: 21rem;
        min-height: 21rem;
    }


    @media (min-width: 767.98px) { 
        height: 25rem;
        min-height: 25rem;
    }

    @media (min-width: 991.98px) { 
        height: 35rem;
        min-height: 35rem;
    }

    @media (min-width: 1199.98px) { 
        height: 40rem;
        min-height: 0rem;
    }

    &video {
        position: absolute;
        display: flex;
        top: 50%;
        left: 50%;
        min-width: 100%;
        min-height: 100%;
        object-fit: cover;
        width: 100%;
        height: 100%;
        z-index: 0;
        -ms-transform: translateX(-50%) translateY(-50%);
        -moz-transform: translateX(-50%) translateY(-50%);
        -webkit-transform: translateX(-50%) translateY(-50%);
        transform: translateX(-50%) translateY(-50%);
    }

    &.container {
        position: relative;
        z-index: 2;
    }

    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: linear-gradient(211.64deg, rgba(0, 0, 0, 0.6) 17.34%, rgba(2, 131, 233, 0.6) 70.82%, rgba(2, 131, 233, 0.6) 155%);
        opacity: 1;
        z-index: 1;
    }

    .header-title-text{
        text-align: center!important;
        box-sizing: border-box;
        padding: 0;
        border: 0;
        font: inherit;
        vertical-align: baseline;
        margin: 0 0 1rem;
        font-family:  ${props => props.theme.fontFamily.font1};
        margin-top: 4rem;
        color: ${props => props.theme.colors.white}!important;
        font-weight: 500;
        font-size: 1.8rem;
        line-height: 3.75rem;

        -webkit-text-size-adjust: 100%;
        -webkit-tap-highlight-color: transparent;
        -webkit-font-smoothing: antialiased;

        @media (min-width:575.98px) { 
            font-size: 2rem;
        }

        @media (min-width: 767.98px) { 
            font-size: 3rem;
        }
    }

    .header-subtitle-text{

      
        text-align: center!important;
        color: ${props => props.theme.colors.white}!important;
        box-sizing: border-box;
        font-family:  ${props => props.theme.fontFamily.font2};
        vertical-align: baseline;
        -webkit-font-smoothing: antialiased;
        margin-bottom: 0.5rem;
        font-size: 1rem;
        font-weight: 300;

        -webkit-text-size-adjust: 100%;
        -webkit-tap-highlight-color: transparent;    

        @media (min-width:575.98px) { 
            margin-bottom: 1rem;
            font-size: 1rem;
        }

        @media (min-width: 767.98px) { 
            margin-bottom: 1rem;
            font-size: 1.25rem;
        }
    }
`;




export const Subtitle = styled.header`
    @media (min-width: 0) { 
        visibility: hidden
    }
    @media (min-width: 767.98px) { 
        visibility: visible
    }
`;


export const VideoFrame = styled.video`
    @media (min-width: 0) { 
        visibility: hidden
    }
    @media (min-width: 767.98px) { 
        visibility: visible
    }
`


