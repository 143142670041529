import React from 'react';
import {
   FooterContainer,
   FooterTopSection,
   FooterLinkTitle,
   FooterText,
   FooterLinkText,
   PhoneIcon,
   FooterSection,
   LocationIcon,
   FooterMissionText,
   TwitterIcon,
   LinkedinIcon,
   InstagramIcon,
   MailIcon,
   WebIcon,
   AfipContainer,
   FooterBottomContainer,
   CopyrightLinkText,
   CopyrightText

} from './Footer.styles';
import LogoImage from '../../../assets/img/isotipo-blanco.svg'
import MapImage from '../../../assets/img/map.svg'
import { useTranslation } from 'react-i18next';
import { Container,Row } from 'react-bootstrap';
import { motion } from 'framer-motion'


function Footer(){
   const { t } = useTranslation();
   
   return (

      <motion.div
         initial={{opacity:0}}
         whileInView={{
               opacity:1
               }}
               transition={{
               duration:1,
               }}
               viewport= {{once:true}} 
         >
      <FooterSection>
         <FooterTopSection>
            <FooterContainer>
               <div className="row justify-content-between">
                  <div className="col-lg-3 mb-3 mb-lg-0">
                     <div className="footer-nav-wrap text-white">
                        <img src={LogoImage} alt="Tech Dream S.A." width="150" className="img-fluid mb-3"/>
                        <FooterMissionText>{t("footer-mision")}</FooterMissionText>
                        <div className="social-list-wrap">
                           <ul className="social-list list-inline list-unstyled">
                              <li className="list-inline-item"><a href={t("social-linkedin-link")} target="_blank"  rel="noreferrer"  title={t("social-linkedin-tittle")}><LinkedinIcon/></a></li>
                              <li className="list-inline-item"><a href={t("social-instragram-link")} target="_blank"  rel="noreferrer" title={t("social-instragram-tittle")}><InstagramIcon/></a></li>
                              <li className="list-inline-item"><a href={t("social-twitter-link")} target="_blank" rel="noreferrer" title={t("social-twitter-title")}><TwitterIcon/></a></li>
                           </ul>
                        </div>
                        <div>
                     

                        </div>

                     </div>
                  </div>
                  <div className="col-lg-3 ml-auto mb-4 mb-lg-0">
                     <div className="footer-nav-wrap text-white">
                        <FooterLinkTitle className="mb-3 text-white">{t('footer-other-title')}</FooterLinkTitle>
                        <ul className="list-unstyled">
                           <li className="mb-2"><FooterLinkText href="/#aboutUs">{t('footer-about-us-link-text')}</FooterLinkText></li>
                           <li className="mb-2"><FooterLinkText href="/#contact">{t('footer-contact-us-link-text')}</FooterLinkText></li>
                           <li className="mb-2"><FooterLinkText href={t('products-link')}>{t('footer-products-us-link-text')}</FooterLinkText></li>
                           <li className="mb-2"><FooterLinkText href={t('mision-link')}>{t('footer-our-mission-link-text')}</FooterLinkText></li>

                        </ul>
                     </div>
                  </div>
                  <div className="col-lg-3 ml-auto mb-4 mb-lg-0">
                     <div className="footer-nav-wrap">
                        <FooterLinkTitle>{t('footer-support-title')}</FooterLinkTitle>
                        <ul className="list-unstyled support-list">
                           <li className="mb-2 d-flex align-items-center"><span><LocationIcon/></span><FooterText>{t('footer-adress')}<br/>{t('footer-state-description')}</FooterText></li>
                           <li className="mb-2 d-flex align-items-center"><span><PhoneIcon/></span><FooterLinkText href={t('phone-number-link')}>{t('footer-telephone-number')}</FooterLinkText></li>
                           <li className="mb-2 d-flex align-items-center"><span><MailIcon/></span><FooterLinkText href={t('email-link')}>{t('footer-email')}</FooterLinkText></li>
                           <li className="mb-2 d-flex align-items-center"><span><WebIcon/></span><FooterLinkText href="/#">{t('footer-page-link')}</FooterLinkText></li>
                        </ul>
                     </div>
                  </div>
                  <div className="col-lg-3">
                     <div className="footer-nav-wrap">
                        <FooterLinkTitle>{t('footer-location-title')}</FooterLinkTitle>
                        <img src={MapImage} alt="location map" className="img-fluid"/>
                     </div>
                  </div>
               </div>
               <AfipContainer> 
                  <a href="http://qr.afip.gob.ar/?qr=Nx_-p1MLAPsB5XA4N6F6ww,," target="_F960AFIPInfo">
                     <img width="80rem" src="https://www.afip.gob.ar/images/f960/DATAWEB.jpg" border="0"/>
                  </a>
               </AfipContainer>
          </FooterContainer>
         </FooterTopSection>
            <FooterBottomContainer className="pt-4 pb-4">
               <Container>
                  <Row className="text-center justify-content-center">
                     <div className="col-md-6 col-lg-5">
                        <CopyrightText className="copyright-text pb-0 mb-0">
                           {t('footer-copyright-line1')}<br/>
                           {t('footer-copyright-line2')}<br/>
                           <CopyrightLinkText href="/#">{t('footer-copyright-line3')}</CopyrightLinkText>
                        </CopyrightText>
                     </div>
                  </Row>
               </Container>
            </FooterBottomContainer>
         </FooterSection>

         </motion.div>
         );
}

export default Footer;
