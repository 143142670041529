import styled from "styled-components";

export const PossitionContainer= styled.div`
    box-sizing: border-box;
    margin: 2.5rem 5rem;
    padding: 0rem;
    border: 0;
    font: inherit;
    vertical-align: baseline;
    position: relative!important;
    :hover{
        .possition-info {
            opacity: 1;
            visibility: visible;
        }
    }

    @media (min-width: 576px) { 
        margin: 2.5rem;
    }
    @media (min-width: 576px) { 
        margin: 2.5rem;
    }
`;

export const PosImage = styled.div`

    text-align: center;
    color: ${props => props.theme.colors.grey1};
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: 0;
    font-family: ${props => props.theme.fontFamily.font2};
    vertical-align: baseline;

`;

export const PossitionImage = styled.img`
    text-align: center;
    color: ${props => props.theme.colors.grey1};
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: 0;
    max-width: 100%;
    border-radius: 50%!important;
    display: block;
    height: 100%;
    width: 100%;
    overflow: hidden;
`;

export const PossitionInfo = styled.div`
    text-align: center;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: 0;
    font-family: ${props => props.theme.fontFamily.font2};
    vertical-align: baseline;
    border-radius: 50%!important;
    display: flex!important;
    flex-direction: column!important;
    justify-content: center!important;
    align-items: center!important;
    color: ${props => props.theme.colors.white}!important;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(188.61deg, rgba(0, 216, 255, 0.95) -21.87%, rgba(2, 131, 233, 0.95) 97.54%, rgba(215, 239, 254, 0.95) 125.68%);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s, visibility 0.3s;
`;

export const Title = styled.h1`
    text-align: center;
    visibility: visible;
    white-space: pre-line;
    box-sizing: border-box;
    padding: 0;
    border: 0;
    vertical-align: baseline;
    -webkit-font-smoothing: antialiased;
    margin: 0 0 1rem;
    font-family: ${props => props.theme.fontFamily.font1};
    font-weight: 600;
    line-height: 1.21;
    font-size: 1.0rem;
    margin-bottom: 0!important;
    color: inherit;
    transition: transform 0.45s cubic-bezier(0.19, 1, 0.22, 1), opacity 0.45s cubic-bezier(0.19, 1, 0.22, 1), -webkit-transform 0.45s cubic-bezier(0.19, 1, 0.22, 1);
    opacity: 1;
    transform: translateY(0);
`;

export const Seniority = styled.h2`
    text-align: center;
    color:  ${props => props.theme.colors.white};
    visibility: visible;
    white-space: pre-line;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-weight: 400;
    border: 0;
    margin-top: 0.5rem;
    font-size: 0.9rem;
    font-family: ${props => props.theme.fontFamily.font2};
    vertical-align: baseline;
    -webkit-font-smoothing: antialiased;
    transition: transform 0.45s cubic-bezier(0.19, 1, 0.22, 1), opacity 0.45s cubic-bezier(0.19, 1, 0.22, 1), -webkit-transform 0.45s cubic-bezier(0.19, 1, 0.22, 1);
    opacity: 1;
    transform: translateY(0);

`;


export const Body = styled.p`
    text-align: center;
    color:  ${props => props.theme.colors.white};
    visibility: visible;
    white-space: pre-line;
    box-sizing: border-box;
    margin: 0;
    margin-top: 0.5rem;
    padding: 0;
    border: 0;
    font-size: 0.8rem;
    font-weight: 300;
    font-family: ${props => props.theme.fontFamily.font2};
    vertical-align: baseline;
    -webkit-font-smoothing: antialiased;
    transition: transform 0.45s cubic-bezier(0.19, 1, 0.22, 1), opacity 0.45s cubic-bezier(0.19, 1, 0.22, 1), -webkit-transform 0.45s cubic-bezier(0.19, 1, 0.22, 1);
    opacity: 1;
    transform: translateY(0);

`;

export const FooterLinkText = styled.a`
        text-align: center;
        text-transform: uppercase;
        font-family: ${props => props.theme.fontFamily.font1};
        color: ${props => props.theme.colors.white};
        list-style: none;
        box-sizing: border-box;
        margin: 0;
        margin-top: 0.5rem;
        padding: 0;
        border: 0;
        vertical-align: baseline;
        -webkit-font-smoothing: antialiased;
        text-decoration: none !important;
        background-color: transparent;
        transition: all .3s ease-in-out;
        font-size: 1rem;
        line-height: 1.188rem;
        opacity: 0.8;
        :hover{
            color: ${props => props.theme.colors.color6};
        }
`;
