import styled from "styled-components";

export const IntegrationsSectionContainer = styled.section`
    text-align: left;
    color: ${props => props.theme.colors.grey1};
    box-sizing: border-box;
    margin: 0;
    border: 0;
    font: inherit;
    display: block;
    background: ${props => props.theme.colors.grey2};
    padding: 3.438rem 0;

`;


export const IntegrationsTitle = styled.h1`
    text-align: center!important;
    box-sizing: border-box;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
    margin: 0 0 1rem;
    font-family: ${props => props.theme.fontFamily.font1};
    font-weight: 600;
    line-height: 1.21;
    color: ${props => props.theme.colors.black};
    font-size: 2.441em;

`;

export const IntegrationsSubTitle = styled.p`

    color: ${props => props.theme.colors.grey1};
    text-align: center!important;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
    -webkit-font-smoothing: antialiased;
    margin-bottom: 1.25em;
    font-size: 1.25rem;
    font-weight: 300;    
    font-family: ${props => props.theme.fontFamily.font2};
`;


export const TechnologyImage = styled.img`
    
    max-width: 100%;
    max-height: 100%;
    -webkit-filter: opacity(50%);
    filter: opacity(90%);
    width: 100%;
`;


export const SingleItem = styled.div`
    
    display: -ms-grid;
    display: grid;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-grid-column-align: center;
    justify-items: center;
    width: 50%;
    height: auto;
    -webkit-transition: -webkit-transform .5s ease-in;
    transition: -webkit-transform .5s ease-in;
    transition: transform .5s ease-in;
    transition: transform .5s ease-in,-webkit-transform .5s ease-in;
    margin: 8px auto;

    @media (min-width: 768px) { 
        width: 30%;
    }


`;
