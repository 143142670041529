import styled from "styled-components";

export const RoundedStyledButton = styled.a`

       box-sizing: border-box;
       margin: 0;
       text-decoration: none !important;
       display: inline-block;
       text-align: center;
       vertical-align: middle;
       user-select: none;
       border: 0.063rem solid transparent;
       font-size: 1rem;
       line-height: 1.5;
       transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
       padding: 1rem 2.5rem;
       font-family: ${props => props.theme.fontFamily.font2};
       font-weight: 700;
       background: ${props => props.theme.colors.white};
       border-color: ${props => props.theme.colors.white};
       color: ${props => props.theme.colors.color1};
       border-radius: 1.875rem;

       -webkit-text-size-adjust: 100%;
       -webkit-tap-highlight-color: transparent;
       -webkit-font-smoothing: antialiased;

       &:hover{
       
           text-decoration: none !important;
           display: inline-block;
           text-align: center;
           vertical-align: middle;
           border: 0.063rem solid transparent;
           font-size: 1rem;
           line-height: 1.5rem;
           transition: color .15s ease-in-out,
           background-color .15s ease-in-out,
           border-color .15s ease-in-out,
           box-shadow .15s ease-in-out;
           -webkit-font-smoothing: antialiased;
           background: transparent;
           border-color: ${props => props.theme.colors.white};
           color: ${props => props.theme.colors.white};

    }
   

`;