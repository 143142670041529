
import styled from "styled-components";



export const StyledServicesCards = styled.div`

    text-align: left;
    color: #707070;
    box-sizing: border-box;
    height: 20rem ;
    margin: 2rem;
    cursor: pointer;
    font: inherit;
    vertical-align: baseline;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    will-change: transform;
    transition: all .2s ease;
    box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.12);
    border: 1px solid #eff2f7;
    border-radius: 1rem;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    -webkit-font-smoothing: antialiased;

    @media (min-width: 576px) { 
        margin: 0.2rem;
    }

    @media (min-width: 992px) { 
        margin: 0;
    }

    & .card-icon{
        color: ${props => props.theme.colors.color7};
        font-size: 2rem;
    }

    &:hover .card-icon{
        color: ${props => props.theme.colors.white};
    }

    & .card-title{
        color: ${props => props.theme.colors.black};
        font-size: 1.25em;
        font-family: ${props => props.theme.fontFamily.font1};
    }

    & .card-body{
        color: ${props => props.theme.colors.color5};
        font-size: 0.9rem;
        font-family: ${props => props.theme.fontFamily.font2};
    }

    & .service-card{
        padding: 1.5rem;
    }
`;

export const CardWrapper = styled.div.attrs({
    className: "col-lg-3 col-sm-6 mb-lg-0"
})`

    &:hover ${StyledServicesCards} {
        will-change: transform;
        transform: translateY(-10px);
        box-shadow: 0 1rem 3rem rgba(31,45,61,.125)!important;
        border: 1px solid #eff2f7;
        background: ${props => props.theme.colors.color6};
        color: #ffffff;
    }

    &:hover ${StyledServicesCards} .card-body{
        color: ${props => props.theme.colors.white};
    }

    &:hover ${StyledServicesCards} .card-title{
        color: ${props => props.theme.colors.white};
    }
`;
