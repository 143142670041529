import './loader';
import { ThemeProvider } from "styled-components";
import theme from "./theme";
import React from 'react';
import { BrowserRouter as Router, Routes, Route} from "react-router-dom";
import NavBar from './presentation/sections/nav-bar/NavBar';
import MainHeader from "./presentation/sections/header/MainHeader";
import Services from "./presentation/sections/services/Services";
import About from "./presentation/sections/about/About";
//import Portfolio from "./presentation/sections/portfolio/Portfolio"
import WorkWithUs from "./presentation/sections/work-with-us/WorkWithUsSection";
import ContactForm from "./presentation/sections/contact-form/ContactForm";
import Technologies from "./presentation/sections/technologies/TechnologiesSection";
import Integrations from "./presentation/sections/integrations/IntegrationsSection";
import VisionAndMission from './presentation/pages/VisionAndMission';

import Footer from "./presentation/sections/footer/Footer";
import GlobalStyles from './GlobalStyles';
import Products from './presentation/pages/Products';
import Vision from './presentation/pages/Vision';
import Mission from './presentation/pages/Mission';

function App() {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    console.log("Development Mode")
  } else {
    console.log = console.warn = console.error = () => {};
  }
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Router>
          <Routes>
            <Route path='/' element={
              <div>
                <NavBar/>
                <MainHeader/>
                <About/>
                <Services/>
                {/*<Portfolio/>*/}
                <WorkWithUs/>
                <Integrations/>
                <Technologies/>
                <ContactForm/>
                <Footer/>
              </div>
            }/> 
            <Route path='/vision-and-mission' element={<VisionAndMission/>}/>
            <Route path='/vision' element={<Vision/>}/>         
            <Route path='/mission' element={<Mission/>}/>          
            <Route path='/products' element={<Products/>}/>         
          </Routes>  
      </Router>
    </ThemeProvider>
  );
}

export default App;
