import { Container,Row } from "react-bootstrap";
import styled from "styled-components";
import ImageBackground from '../../../assets/img/footer-bg.svg'
import { TiSocialFacebook,TiSocialTwitter, TiSocialInstagram, TiSocialLinkedin, TiLocation, TiPhone, TiMail, TiWorld } from "react-icons/ti";

export const FooterSection = styled.footer`
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
`;


export const FooterContainer = styled(Container)`
    width: 100%;
    padding-right: 0.938rem;
    padding-left: 0.938rem;
    margin-right: auto;
    margin-left: auto;
`;



export const FooterMissionText = styled.p`
    
    font-family: ${props => props.theme.fontFamily.font2};
    color: ${props => props.theme.colors.white};
    text-align: left;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
    margin-bottom: 1.25em;

`;

export const FooterLinkTitle = styled.h1`
    font-family: ${props => props.theme.fontFamily.font2};
    color: ${props => props.theme.colors.white};
    text-align: left;
    box-sizing: border-box;
    padding: 0;
    border: 0;
    vertical-align: baseline;
    -webkit-font-smoothing: antialiased;
    margin: 0 0 1rem;
    font-weight: 600;
    line-height: 1.21;
    font-size: 1.25em;
    margin-bottom: 1rem!important;

`;

export const FooterLinkText = styled.a`
        font-family: ${props => props.theme.fontFamily.font1};
        color: ${props => props.theme.colors.white};
        list-style: none;
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        border: 0;
        vertical-align: baseline;
        -webkit-font-smoothing: antialiased;
        text-decoration: none !important;
        background-color: transparent;
        transition: all .3s ease-in-out;
        font-size: 0.875rem;
        line-height: 1.188rem;
        opacity: 0.8;
        :hover{
            color: ${props => props.theme.colors.color1};
        }
`;

export const FooterText = styled.p`
        font-family: ${props => props.theme.fontFamily.font1};
        color: ${props => props.theme.colors.white};
        -webkit-text-size-adjust: 100%;
        list-style: none;
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        border: 0;
        vertical-align: baseline;
        -webkit-font-smoothing: antialiased;
        text-decoration: none !important;
        background-color: transparent;
        transition: all .3s ease-in-out;
        font-size: 0.875rem;
        line-height: 1.188rem;
        opacity: 0.8;
`;


export const FooterTopSection = styled.div`

    text-align: left;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: 0;
    padding-bottom: 3rem!important;
    padding-top: 150px;
    position: relative;
    display: block;
    width: 100%;
    background-image: url(${ImageBackground});
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;

`;


export const FooterBottomSection = styled.div`
    text-align: left;
    color: #707070;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
    padding-top: 1.5rem!important;
    padding-bottom: 1.5rem!important;
    background: #f4f7fa;

`;

export const TwitterIcon = styled(TiSocialTwitter)`
    height: 3rem;
    width: 3rem;
    color: ${props => props.theme.colors.white};
    &:hover{
        color: ${props => props.theme.colors.color1};
    }
`;

export const FacebookIcon = styled(TiSocialFacebook)`
    height: 3rem;
    width: 3rem;
    color: ${props => props.theme.colors.white};
    &:hover{
        color: ${props => props.theme.colors.color1};
    }
`;

export const InstagramIcon = styled(TiSocialInstagram)`
    height: 3rem;
    width: 3rem;
    color: ${props => props.theme.colors.white};
    &:hover{
        color: ${props => props.theme.colors.color1};
    }
`;

export const LinkedinIcon = styled(TiSocialLinkedin)`
    height: 3rem;
    width: 3rem;
    color: ${props => props.theme.colors.white};
    &:hover{
        color: ${props => props.theme.colors.color1};
    }
`;

export const LocationIcon = styled(TiLocation)`
    margin-right: 10px;
    color: ${props => props.theme.colors.white};
`;

export const PhoneIcon = styled(TiPhone)`
    margin-right: 10px;
    color: ${props => props.theme.colors.white};
`;

export const MailIcon = styled(TiMail)`
    margin-right: 10px;
    color: ${props => props.theme.colors.white};
`;

export const WebIcon = styled(TiWorld)`
    margin-right: 10px;
    color: ${props => props.theme.colors.white};
`;

export const AfipContainer= styled(Row)`
    padding-top:4rem;
    padding-bottom: 2rem;
 `;   

export const FooterBottomContainer= styled.div`
    text-align: left;
    color: ${props => props.theme.colors.white};
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
    padding-top: 1.5rem!important;
    padding-bottom: 1.5rem!important;
    background: ${props => props.theme.colors.color6};
`;

export const CopyrightText = styled.p`
 
    color: ${props => props.theme.colors.white};
    text-align: center!important;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
    -webkit-font-smoothing: antialiased;
    margin-bottom: 0!important;
    padding-bottom: 0!important;
    font-size: 0.875rem;
    font-family: ${props => props.theme.fontFamily.font2};
`;

export const CopyrightLinkText = styled.a`
    font-family: ${props => props.theme.fontFamily.font1};
    color: ${props => props.theme.colors.white};
    list-style: none;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
    -webkit-font-smoothing: antialiased;
    text-decoration: none !important;
    background-color: transparent;
    transition: all .3s ease-in-out;
    font-size: 0.875rem;
    line-height: 1.188rem;
    opacity: 0.8;
    :hover{
        color: ${props => props.theme.colors.color1};
    }
`;