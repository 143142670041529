import { FaBars, FaTimes } from "react-icons/fa";
import styled from "styled-components";
import { NavHashLink } from 'react-router-hash-link';
import logo from '../../../assets/img/logo.svg';


export const Nav = styled.nav`
    background: transparent;
    height: 4.5rem;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 0.2rem;
    padding-left: 2rem;
    padding-right: 2rem;
    z-index: 12;
    position: fixed;
    width: 100%;
    align-items: 'center';

    @media (min-width: 768px) { 
      padding-left: 5rem;
      padding-right: 5rem;
    }

    @media (min-width: 1200px) { 
      padding-left: 10rem;
      padding-right: 10rem;
    }

    &.affix{
      background-image: linear-gradient(to right, ${props => props.theme.colors.color6}, ${props => props.theme.colors.color1}, ${props => props.theme.colors.color7}, ${props => props.theme.colors.color7}, ${props => props.theme.colors.color6}) !important;};

`;



export const NavLogo = styled(NavHashLink)`
  cursor: pointer;
  background: url(${logo});
  background-size: cover;
  height: 3.0rem;
  width: 3.0rem;
`;

export const NavLink = styled(NavHashLink)`
  color: ${props => props.theme.colors.white};
  margin: 0.5rem;
  display: flex;
  align-items: center;
  text-align: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  font-family: ${props => props.theme.fontFamily.font1};
  font-style: normal;
  font-weight: 300;
  font-size: 0.9rem;
  line-height: 1.5rem;
  cursor: pointer;
  border-bottom: solid;
  border-color: transparent;
`;

export const Bars = styled(FaBars)`
  display: none;
  color: ${props => props.theme.colors.white};
  @media screen and (max-width: 768px) {
    display: block;
    font-size: 1.8rem;
    cursor: pointer;
  }
`;

export const Close = styled(FaTimes)`
  display: none;
  color: ${props => props.theme.colors.white};
  @media screen and (max-width: 768px) {
    display: block;
    font-size: 1.8rem;
    cursor: pointer;
  }
`;

export const NavMenu = styled.div`
  display: flex;
  align-items: center;
  margin-right: -24px;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;


export const ResponsiveNavMenu = styled.div`
  display: flex;
  position: fixed;
  width: 100%;
  height: 100vh;
  align-items: center;
  padding-top: 6rem;
  z-index: 11;
  background: ${props => props.theme.colors.color7};
  @media screen and (min-width: 768px) {
    display: none;
  }
`;
