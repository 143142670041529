import React from 'react';
import {VideoHeader, Subtitle, VideoFrame} from './MainHeader.styles';
import Video  from '../../../assets/video/td-movie.mp4';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import  RoundedButton  from '../../components/rounder-button/RoundedButton'
import { motion } from 'framer-motion'

function MainHeader(){
   const { t } = useTranslation();
   return (

   <VideoHeader>
      <div className="header overlay">
      <Container className="h-100">
         <Row className="h-100">
         
            <Col  className="align-self-center text-center">
              
              <Row>
                 
                 <Col className="header-title-text">
                 <motion.div
                  initial={{opacity:0}}
                  whileInView={{
                      opacity:1
                    }}
                    transition={{
                      duration:2.7,
                    }}
                    viewport= {{once:true}} 
                 >
                  {t('header-title')}
                  </motion.div>

                  </Col>

                
              </Row>
              <Row>
                  <Col className="header-subtitle-text ">
                  <Subtitle>
                     <motion.div
                     initial={{opacity:0}}
                     whileInView={{
                        opacity:1
                     }}
                     transition={{
                        duration:3.7,
                     }}
                     viewport= {{once:true}} 
                  >
                        {t('header-subtitle')}
                  </motion.div>
                  </Subtitle>
                  </Col>
              </Row>

              <Row>
                  <Col  className="align-self-center text-center">

                     <RoundedButton width="30rem" href={t("whatsapp-me")}> {t('header-chat-button')}</RoundedButton>
                  
                  </Col>
              </Row> 

            </Col>

         </Row>
      </Container>
      </div>
      <VideoFrame height="auto" width="100%" playsInline="playsInline" autoPlay="autoplay" muted="muted" loop="loop">
            <source src={Video} type="video/mp4"/>
      </VideoFrame>
      
     
   </VideoHeader>
   
   );
}

export default MainHeader;
