import React from 'react';
import {ServicesSection,ServiceTitleContainer} from './Services.styles';
import { Container, Row } from 'react-bootstrap';
import ServicesCards from '../../components/services-cards/ServicesCards';  
import { FaDigitalOcean, FaChalkboardTeacher, FaMobileAlt } from "react-icons/fa" ;
import { ImCog } from "react-icons/im";
import { FiServer } from "react-icons/fi";
import { useTranslation } from 'react-i18next';
import { BiBot } from "react-icons/bi";
import { motion } from 'framer-motion'


function Services(){
   const { t } = useTranslation();
   return (
       <ServicesSection id="services">
            <motion.div
                  initial={{opacity:0}}
                  whileInView={{
                        opacity:1
                        }}
                        transition={{
                        duration:1,
                        }}
                        viewport= {{once:true}} 
                  >
            <Container >
                   <ServiceTitleContainer>
                        
                              <h1 className="title">{t('services-title')}</h1>
                              <div className="title-description">
                                    <h2 className="w-50 p-3">{t('services-title-description')}</h2>
                              </div>
                        
                  </ServiceTitleContainer>
                
                  <Row className="justify-content-center gap-3">  
                        <ServicesCards
                              title={t('services-card-automatization-title')}
                              body={t('services-card-automatization-body')}
                              icon={ImCog}
                              url={t('services-card-automatization-url')}
                        />
                        <ServicesCards
                              title={t('services-card-development-backend-title')}
                              body={t('services-card-development-backend-body')}
                              icon={FiServer}
                              url={t('services-card-development-backend-url')}
                        />
                        <ServicesCards
                              title={t('services-card-digital-transformation-title')}
                              body={t('services-card-digital-transformation-body')}
                              icon={FaDigitalOcean}
                              url={t('services-card-digital-transformation-url')}
                        />
                        <ServicesCards
                              title={t('services-card-academy-title')}
                              body={t('services-card-academy-body')}
                              icon={FaChalkboardTeacher}
                              url={t('services-card-academy-url')}
                        />
                        <ServicesCards
                              title={t('services-card-development-mobile-title')}
                              body={t('services-card-development-mobile-body')}
                              icon={FaMobileAlt}
                              url={t('services-card-development-mobile-url')}
                        />
                        <ServicesCards
                              title={t('services-card-ai-integration-title')}
                              body={t('services-card-ai-integration-body')}
                              icon={BiBot}
                              url={t('services-card-ai-integration-url')}
                        />
                   </Row>                                    
             </Container>

            </motion.div>
            

       </ServicesSection>
      );
}


export default Services;
